module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/ryan.rowe.codes/ryan.rowe.codes/node_modules/gatsby-remark-prismjs","id":"58a108b4-3219-5da7-a784-9ee908c63922","name":"gatsby-remark-prismjs","version":"6.20.0","modulePath":"/home/runner/work/ryan.rowe.codes/ryan.rowe.codes/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"showLineNumbers":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/ryan.rowe.codes/ryan.rowe.codes","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
